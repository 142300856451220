import { createStore } from 'vuex'

import cookieConsent from './modules/cookieConsent';
import pageTitle from './modules/pageTitle';

export default createStore({
  modules: {
    cookieConsent,
    pageTitle,
  }
})