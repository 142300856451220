<template>

    <!-- START FOOTER TOP DESIGN AREA -->
    <section class="footer-top-area section-padding">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <div class="single-footer-top">
                        <h2>Über Uns</h2>
                        <p>Entdecken Sie mit Reisebüro Burkhard in Mammendorf die Vielfalt der Reisewelt. Ob erlebnisreiche Urlaubs- und Studienreisen, beruhigende Wellness-Erlebnisse oder Ferienhäuser für alle - hier startet Ihre Reise ins Glück</p>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="single-footer-top">
                        <h2>Reisen</h2>
                        <div class="footer-menu">
                            <ul>
                              <li v-for="item in navigationHeader" :key="item.key">
                                <router-link :to="{ name: item.href }">{{ item.name }}</router-link>
                              </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="single-footer-top">
                        <h2>Kontakt</h2>
                        <div class="single-contact">
                            <h3><i class="fa fa-map"></i>Reisebüro Burkhard, 82291 Mammendorf</h3>
                        </div>
                        <div class="single-contact">
                            <h3><i class="fa fa-phone"></i>(0 81 45) 12 68</h3>
                        </div>
                        <div class="single-contact">
                            <h3> <i class="fa fa-envelope"></i>info@reisebuero-burkhard.de</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
     <!-- / END FOOTER TOP DESIGN AREA -->


  <!-- START FOOTER DESIGN AREA -->
  <footer class="footer-area section-padding">
      <div class="container">
          <div class="row">
              <div class="col-sm-6">
                  <div class="footer-text">
                      <h6>&copy; {{ year }} {{ globalVars.appName }} </h6>
                  </div>
              </div>
              <div class="col-sm-6">
                  <div class="footer-text">
                      <div class="footer-bottom-menu">
                          <ul>
                            <li v-for="item in navigation" :key="item.key">
                              <router-link :to="{ name: item.href }">{{ item.name }}</router-link>
                            </li>                              
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </footer>
  <!-- / END CONTACT DETAILS DESIGN AREA -->

</template>



<script>
// import Header from '@/components/Header.vue'
import { ref, inject } from 'vue'

export default {
  name: 'Footer',
  components: {
    // Header,
  }, 
  setup() {

    const globalVars = inject('globalVars')

    const navigation = [
      { name: 'Impressum', key: 'Impressum', href: 'Impressum' },
      { name: 'AGB', key: 'Agb', href: 'Agb' },
      { name: 'Datenschutz', key: 'Datenschutz', href: 'Datenschutz' },
    ]

    var dateObj = new Date();
    var year = dateObj.getUTCFullYear();

    const navigationHeader = [
      { name: 'Urlaubsreisen', key: 'ur', href: 'Urlaubsreisen' },
      { name: 'Studienreisen', key: 'sr', href: 'Studienreisen' },
      { name: 'Specials', key: 'specials', href: 'Specials' },
      { name: 'Über Uns', key: 'uu', href: 'UeberUns' },
    ]

    return {
      globalVars,
      navigation,
      year,
      navigationHeader,
    }
 
  }
}

</script>