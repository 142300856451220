import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../views/Impressum.vue')
  },
  {
    path: '/agb',
    name: 'Agb',
    component: () => import('../views/Agb.vue')
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import('../views/Datenschutz.vue')
  },
  {
    path: '/urlaubsreisen',
    name: 'Urlaubsreisen',
    component: () => import('../views/Urlaubsreisen.vue')
  },
  {
    path: '/studienreisen',
    name: 'Studienreisen',
    component: () => import('../views/Studienreisen.vue')
  },
  {
    path: '/specials',
    name: 'Specials',
    component: () => import('../views/Specials.vue')
  },
  {
    path: '/ueber-uns',
    name: 'UeberUns',
    component: () => import('../views/UeberUns.vue')
  },
  {
    path: '/reisethema-fluege-mietauto',
    name: 'ReiseThFluege',
    component: () => import('../views/ReiseThFluege.vue')
  },
  {
    path: '/reisethema-autoreisen-eigenreise',
    name: 'ReiseThAuto',
    component: () => import('../views/ReiseThAuto.vue')
  },
  {
    path: '/reisethema-wellness-fitness-kuren',
    name: 'ReiseThWellness',
    component: () => import('../views/ReiseThWellness.vue')
  },
  {
    path: '/reisethema-ferienhauser-ferienwohnungen',
    name: 'ReiseThFerienha',
    component: () => import('../views/ReiseThFerienha.vue')
  },
  {
    path: '/reisethema-cluburlaub-and-more',
    name: 'ReiseThCluburluab',
    component: () => import('../views/ReiseThCluburluab.vue')
  },
  {
    path: '/reisethema-lunux-und-ferienreisen',
    name: 'ReiseThLuxus',
    component: () => import('../views/ReiseThLuxus.vue')
  },
  {
    path: '/reisethema-hotels',
    name: 'ReiseThHotels',
    component: () => import('../views/ReiseThHotels.vue')
  },
  {
    path: '/reisethema-kreuzfahrten',
    name: 'ReiseThKreuzfahrten',
    component: () => import('../views/ReiseThKreuzfahrten.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
