const state = {
  show: false,
  accepted: false
}

const getters = {
  show(state, getters, rootState) {
    return state.show;
  },
  accepted(state, getters, rootState) {
    return state.accepted;
  },
}

const mutations = {
  setShow(state, value) {
    state.show = value
  },
  setAccepted(state, value) {
    state.accepted = value
  },
}



const cookieConsent = {
  namespaced: true,
  state,
  mutations,
  getters
}

export default cookieConsent;