<template>
  <!-- START HOMEPAGE DESIGN AREA -->
  <header id="home" class="welcome-area">
      <div class="header-top-area">
          <div class="container">
              <div class="row">
                  <div class="header-top">
                      <div class="col-md-9 col-sm-8">
                          <div class="tour-info">
                              <ul>
                                  <li><i class="fa fa-phone"></i>(0 81 45) 12 68</li>
                                  <li><i class="fa fa-envelope"></i><a href="mailto:info@reisebuero-burkhard.de" class="tour-info-email"><span class="text-lowercase">info@reisebuero-burkhard.de</span></a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-3">
                      <!-- START LOGO DESIGN AREA -->
                      <div class="logo">
                        <router-link :to="{ name: 'Home' }" class="inline">
                          <p>{{ globalVars.appName }}</p>
                        </router-link>
                      </div>
                      <!-- END LOGO DESIGN AREA -->
                  </div>
                  <div class="col-md-9">
                      <!-- START MENU DESIGN AREA -->
                      <div class="mainmenu">
                          <div class="navbar navbar-nobg">
                              <div class="navbar-header">
                                  <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                      <span class="sr-only">Toggle navigation</span>
                                      <span class="icon-bar"></span>
                                      <span class="icon-bar"></span>
                                      <span class="icon-bar"></span>
                                  </button>
                              </div>
                              <div class="navbar-collapse collapse">
                                  <ul class="nav navbar-nav navbar-right">

                                    <!-- <div v-for="item in navigation.main" :key="item.key" class="pb-6">
                                      <router-link :to="{ name: item.href }" :rel="item.rel" class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{ item.name }}</router-link>
                                    </div> -->

                                    <li v-for="item in navigationHeader" :key="item.key" class="active">
                                    <router-link :to="{ name: item.href }" class="smoth-scroll">{{ item.name }}</router-link>
                                    <!-- <a :href="item.href" class="smoth-scroll">{{ item.name }}</a> -->
                                    </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <!-- END MENU DESIGN AREA -->
                  </div>
              </div>
          </div>
      </div>
      <div class="welcome-image-area version" data-stellar-background-ratio="0.6" style="background: url(/assets/images/Thailand-Beaches-and-Scenery.jpg); background-size: cover;">
          <div class="display-table">
              <div class="display-table-cell">
                  <div class="container">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="header-text text-center">
                                  <h1>{{ pageTitle }}</h1>
                                  <!-- <p>Travel to the any corner of the world, without going around in circles connect with us.</p> -->
                                  <p>&nbsp;</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </header>
  <!-- / END HOMEPAGE DESIGN AREA -->  
</template>



<script>
// import Header from '@/components/Header.vue'
import { ref, inject } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Header',
  components: {
    // Header,
  }, 
  setup() {

    const globalVars = inject('globalVars')
    const store = useStore()

    const pageTitle = ref('')

    store.watch((state, getters) => store.getters['pageTitle/title'], () => {
      pageTitle.value = store.getters['pageTitle/title']
    })       

    const navigationHeader = [
      { name: 'Urlaubsreisen', key: 'ur', href: `Urlaubsreisen` },
      { name: 'Studienreisen', key: 'sr', href: `Studienreisen` },
      { name: 'Specials', key: 'specials', href: `Specials` },
      { name: 'Über Uns', key: 'uu', href: `UeberUns` },
    ]
    
    // const navigationHeader = [
    //   { name: 'Urlaubsreisen', key: 'ur', href: `/urlaubsreisen` },
    //   { name: 'Studienreisen', key: 'sr', href: `/studienreisen` },
    //   { name: 'Specials', key: 'specials', href: `/specials` },
    //   { name: 'Über Uns', key: 'uu', href: `/ueber-uns` },
    // ]

    // const { tm } = useI18n()

    // // Vue Meta
    // const { meta } = useMeta({
    //   title: tm('seo.title'),
    //   htmlAttrs: { lang: 'en-US', amp: false },
    //   description: tm('seo.description'),
    //   script: [],
    // })

    return {
      globalVars,
      navigationHeader,
      pageTitle,
    }
 
  }
}

</script>