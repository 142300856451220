<template>

  <Container>
    <div class="row">

      <div class="col-md-9">
        <div v-if="state === 'success'">
          <PrismicRichText :field="document.data.content" />
          <br />
        </div>
      </div>
      <div class="col-md-3">
        <ReisethemenMenu />
      </div>

    </div>
  </Container>

</template>



<script>

import { usePrismicDocumentByUID } from "@prismicio/vue";
import { useMeta } from 'vue-meta'
import { onMounted, watch, ref } from 'vue'
import Container from '@/components/Container.vue'
import ReisethemenMenu from '@/components/ReisethemenMenu.vue'
import { useStore } from 'vuex'

export default {
  name: 'Impressum',
  components: {
    Container,
    ReisethemenMenu,
  }, 
  setup() {  

    onMounted(() => { 
      window.scrollTo(0, 0);
    });

    const { meta } = useMeta({ })
    const store = useStore()

    const { data: document, state, error, refresh } = usePrismicDocumentByUID("page", "home");    
    
    watch(() => state.value, (first, second) => {
      if (first === 'success') {
        meta.title = document.value.data.meta_title;
			  meta.description = document.value.data.meta_description;
        store.commit('pageTitle/setTitle', document.value.data.meta_title)
      }
    });   
  

    return {
      document,
      state
    }

  }
}
</script>