<template>

  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `${appName}` }}</template>
    <template v-slot:description="{ content }">{{ content }}</template>
  </metainfo>

  <Header />



  <router-view/>
  <Footer />
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { provide, defineAsyncComponent, computed } from 'vue'

import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  }, 
  setup() {

    // const { tm } = useI18n()

    // Global Vars
    const globalVars = {
      appName: 'Reisebüro Burkhard',
      appUrl: process.env.VUE_APP_URL,
      googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      social: {
        twitter: {
          acc: '@fullstackplanet',
          uri: 'https://twitter.com/FullStackPlanet'
        },
      },
      prismicApiEndpoint: process.env.VUE_APP_PRISMIC_API_ENDPOINT,
      prismicRepoName: process.env.VUE_APP_PRISMIC_REPO_NAME,
      env: process.env.VUE_APP_ENV,
    }
    provide('globalVars', globalVars);

    // Vue Meta
    const { meta } = useMeta({
      title: `${globalVars.appName} Ihr Reisebüro für Fürstenfeldbruck und Umgebung`,
      htmlAttrs: { lang: 'de-DE', amp: false },
      description: 'Ihr lokales Reisebüro in Mammendorf für Traumurlaube weltweit! Maßgeschneiderte Auslandsreisen & Erlebnisse. 🌴 ✈️ Jetzt buchen & unvergessliche Momente genießen!',
      script: [],
    })    

  }
}

</script>
