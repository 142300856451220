const state = {
  title: ''
}

const getters = {
  title(state, getters, rootState) {
    return state.title;
  },
}

const mutations = {
  setTitle(state, value) {
    state.title = value
  },
}



const pageTitle = {
  namespaced: true,
  state,
  mutations,
  getters
}

export default pageTitle;