<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      Reisethemen
    </div>
    <div class="panel-body">

      <div v-for="item in navigationHeader" :key="item.key" style="margin-bottom: 10px;">
        <router-link :to="{ name: item.href }">{{ item.name }}</router-link>
      </div>      

    </div>
  </div>

</template>



<script>
import { ref, inject } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'ReisethemenMenu',
  components: {
    // Header,
  }, 
  setup() {

    const navigationHeader = [
      { name: 'FLÜGE / MIETAUTOS', key: 'ReiseThAuto', href: `ReiseThFluege` },
      { name: 'AUTOREISEN / EIGENANREISE', key: 'sr', href: `ReiseThAuto` },
      { name: 'WELLNESS / FITNESS / KUREN', key: 'ReiseThWellness', href: `ReiseThWellness` },
      { name: 'FERIENHÄUSER und WOHNUNGEN', key: 'ReiseThFerienha', href: `ReiseThFerienha` },
      { name: 'CLUBURLAUB - ME & MORE', key: 'ReiseThCluburluab', href: `ReiseThCluburluab` },
      { name: 'LUXUS- und FERNREISEN', key: 'ReiseThLuxus', href: `ReiseThLuxus` },
      { name: 'HOTELS: Booking.com - HRS', key: 'ReiseThHotels', href: `ReiseThHotels` },
      { name: 'KREUZFAHRTEN: Fluss und Meer', key: 'ReiseThKreuzfahrten', href: `ReiseThKreuzfahrten` },
    ]

    return {
      navigationHeader,
    }
 
  }
}

</script>